import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import {
    useAccount,
    useContractWrite,
    useNetwork,
    useSwitchNetwork,
    useWaitForTransaction,
} from 'wagmi';
import _contracts_web3 from '../../configs/contracts.web3';
import { CONFIG } from '../../configs/platform.config';
import { notify } from '../../utils/common.helper';
import {
    chainSymbolById,
    formatNumber,
    formateDate,
    parseRevertReason,
} from '../../utils/helper';
import ButtonLoader from '../Loader/ButtonLoader';

const RoyaltyCard = ({ data, isWithdraw = false, refetch }) => {
    const [imageUrl, setImageUrl] = useState();
    const modelRef = useRef();
    const { address } = useAccount();
    const { chain } = useNetwork();
    const { switchNetwork } = useSwitchNetwork({
        onSuccess: () => {
            writeClaimRoyalty({
                args: [
                    data?.aTransaction?.sTokenAddress ||
                    '0x0000000000000000000000000000000000000000',
                    data?.aNFT?.sCollectionAddress,
                    data?.aNFT?.nTokenId,
                ],
            });
        },
    });

    const {
        data: claimRoyalty,
        write: writeClaimRoyalty,
        isLoading: isLoadingClaimRoyalty,
    } = useContractWrite({
        address: _contracts_web3.addresses[data?.aNFT?.nChainId]?.media,
        abi: _contracts_web3.abis.media,
        functionName: 'withdrawRoyalty',
        account: address,
        onError(error) {
            notify('error', parseRevertReason(error.shortMessage));
        },
    });

    const {
        isLoading: isClaimRoyaltyLoading,
        isSuccess: isClaimRoyaltySuccess,
    } = useWaitForTransaction({
        hash: claimRoyalty?.hash,
    });

    useEffect(() => {
        if (isClaimRoyaltySuccess) {
            notify('success', 'Royalty claimed successfully');
        }
        refetch?.();
    }, [isClaimRoyaltySuccess]);

    useEffect(() => {
        setImageUrl(
            `${data?.aNFT?.bIsMigrated ? CONFIG.sPinataIpfsUri : CONFIG.sFilebaseIpfsUri}${data?.aNFT?.sImageHash}`
        );
    }, [data]);

    const handleError = () => {
        setImageUrl(
            `${CONFIG.sIpfsUri}${data?.aNFT?.sImageHash}`
        );
    };

    return (
        <div className='flex gap-5 items-center bg-dark-10 rounded-2xl p-6 xl:p-4 xl:gap-3.5 md:w-full xl:flex-wrap'>
            <div>
                <div className='relative w-24 pt-[100%] rounded-2xl overflow-hidden 2xl:w-24 xl:w-20 sm:w-16'>
                    <Link
                        to={`/nft/${data?.aNFT?._id}`}
                        className='absolute top-0 left-0 w-full h-full z-10'
                    ></Link>
                    {data?.aNFT?.eType === 'image' ? (
                        <img
                            src={imageUrl}
                            alt='NftImg'
                            className='NftImg absolute left-0 top-0 object-cover object-center'
                            onError={handleError}
                        />
                    ) : data?.aNFT?.eType === 'video' ? (
                        <video
                            src={imageUrl}
                            className='NftImg absolute left-0 top-0 object-cover object-center'
                            onError={handleError}
                        />
                    ) : data?.aNFT?.eType === 'model' ? (
                        <model-viewer
                            alt={data?.aNFT?.sName}
                            src={imageUrl}
                            ar
                            autoplay
                            poster={imageUrl}
                            shadow-intensity='1'
                            camera-controls={false}
                            ref={(ref) => {
                                modelRef.current = ref;
                            }}
                            touch-action='pan-y'
                            class='NftImg absolute left-0 top-0 object-cover object-center'
                            onError={handleError}
                        ></model-viewer>
                    ) : null}
                </div>
            </div>
            <div className='flex-1'>
                <h5 className='text-2xl font-bold text-black xl:text-xl sm:text-lg'>
                    {data?.aNFT?.sName}
                </h5>
                <p className='text-base text-black font-bold sm:text-sm'>
                    {formatNumber(data?.sRoyalty)}{' '}
                    {data?.aTransaction?.sTokenSymbol ||
                        chainSymbolById(data?.aNFT?.nChainId)}
                </p>
                <p className='text-base text-dark-70 3xl:text-sm'>
                    {formateDate(data?.dCreatedAt)}
                </p>
            </div>
            <div className='xs:w-full'>
                {isWithdraw ? (
                    <span className='px-3 uppercase tracking-wider text-sm text-black font-bold sm:p-0'>
                        Claimed
                    </span>
                ) : (
                    <button
                        className='btn-primary z-10'
                        disabled={
                            isLoadingClaimRoyalty || isClaimRoyaltyLoading
                        }
                        onClick={async (e) => {
                            e.preventDefault();

                            if (chain.id !== data?.aNFT?.nChainId) {
                                switchNetwork(data?.aNFT?.nChainId);
                            } else {
                                writeClaimRoyalty({
                                    args: [
                                        data?.aTransaction?.sTokenAddress ||
                                        '0x0000000000000000000000000000000000000000',
                                        data?.aNFT?.sCollectionAddress,
                                        data?.aNFT?.nTokenId,
                                    ],
                                });
                            }
                        }}
                    >
                        <span className='py-1.5 sm:p-0'>
                            {(isLoadingClaimRoyalty ||
                                isClaimRoyaltyLoading) && <ButtonLoader />}
                            Withdraw
                        </span>
                    </button>
                )}
            </div>
        </div>
    );
};

export default RoyaltyCard;
