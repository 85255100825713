import '@google/model-viewer/dist/model-viewer';
import { useMutation, useQuery } from '@tanstack/react-query';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom';
import {
    useAccount,
    useContractWrite,
    useNetwork,
    useSwitchNetwork,
    useWaitForTransaction,
} from 'wagmi';
import { addToCart, getCart } from '../../api/adapters/cart';
import {
    getBidActivity,
    getNFTActivity,
    getNFTDetails,
    likeNft,
    unLikeNft,
} from '../../api/adapters/nft';
import _contracts_web3 from '../../configs/contracts.web3';
import { CONFIG } from '../../configs/platform.config';
import CHAINS_WEB3 from '../../configs/web3-chains';
import { updateCart } from '../../redux/actions/cart';
import { toggleLoader } from '../../redux/actions/loader';
import { notify } from '../../utils/common.helper';
import { getCookie } from '../../utils/cookies.helper';
import {
    chainSymbolById,
    formatNumber,
    goBack,
    parseDate,
    parseRevertReason,
    trimEthereumAddress,
} from '../../utils/helper';

// COMPONENTS
import ButtonLoader from '../../components/Loader/ButtonLoader';
import BidNowPopup from '../../components/Modal/BidNowPopup';
import BurnNftPopup from '../../components/Modal/BurnNftPopup';
import BuyNowPopup from '../../components/Modal/BuyNowPopup';
import PutNftOnSalePopup from '../../components/Modal/PutNftOnSalePopup';
import RemoveFromSalePopup from '../../components/Modal/RemoveFromSalePopup';
import TransferNFTPopup from '../../components/Modal/TransferNFTPopup';
import VideoModel from '../../components/Modal/VideoModel';
import Timer from '../../components/Timer/Timer';

// IMAGES
import BSC from '../../assets/images/Binance-icon.svg';
import BOBA from '../../assets/images/Boba-icon.svg';
import ETH from '../../assets/images/Ethereum-icon.svg';
import MATIC from '../../assets/images/Polygon-icon.svg';
import CloneIcon from '../../assets/images/clone-icon.svg';
import CollapseIcon from '../../assets/images/collapse-arrow.svg';
import LikeBlankIcon from '../../assets/images/like-border-icon.svg';
import LikeIcon from '../../assets/images/liked-icon.svg';
import LockIcon from '../../assets/images/lock-icon.svg';
import ArrowIcon from '../../assets/images/next-arrow.svg';
import OMI from '../../assets/images/omi-icon.svg';
import PlayIcon from '../../assets/images/play-icon.svg';
import USDC from '../../assets/images/usd-icon.svg';
import CollaboratorsPopup from '../../components/Modal/CollaboratorsPopup';

function NftDetail() {
    const [isLike, setIsLike] = useState(false);
    const [isRemoveFromSalePopupOpen, setIsRemoveFromSalePopupOpen] =
        useState(false);
    const [isHideNftPopupOpen, setIsHideNftPopupOpen] = useState(false);
    const [isTransferNftPopupOpen, setIsTransferNftPopupOpen] = useState(false);
    const [isPutOnSalePopupOpen, setIsPutOnSalePopupOpen] = useState(false);
    const [isBurnNftPopupOpen, setIsBurnNftPopupOpen] = useState(false);
    const [isBuyNowPopupOpen, setIsBuyNowPopupOpen] = useState(false);
    const [isVideoPopupOpen, setIsVideoPopupOpen] = useState(false);
    const [isBidNowPopupOpen, setIsBidNowPopupOpen] = useState(false);
    const [isOwner, setIsOwner] = useState(false);
    const [isInCart, setIsInCart] = useState(false);
    const [stopTimer, setStopTimer] = useState(false);
    const [imageUrl, setImageUrl] = useState();
    const [isOnSale, setIsOnSale] = useState(false);
    const [saleQuantity, setSaleQuantity] = useState(0);
    const [eBidStatus, setEBidStatus] = useState('');
    const [isPropertiesShow, setIsPropertiesShow] = useState(true);
    const [isContentShow, setIsContentShow] = useState(true);
    const [isCollaboratorsPopupOpen, setIsCollaboratorsPopupOpen] =
        useState(false);

    const modelRef = useRef();
    const chainIcon = {
        ETH: ETH,
        BNB: BSC,
        MATIC: MATIC,
        USDC: USDC,
        BOBA: BOBA,
        OMI: OMI,
    };
    const { address, isConnected } = useAccount();
    const { id } = useParams();
    const { switchNetwork } = useSwitchNetwork({
        onSuccess: () => {
            writeClaimNft({
                args: [
                    nft?.sCollectionAddress,
                    nft?.nTokenId,
                    nft?.oCurrentOwner?.sWalletAddress,
                    nft?.aTransaction?.[0]?.nQuantity,
                    nft.oBrand?.matchedTierInfo?.[0]?.tierDetails
                        ?.nTierCommission * 100 || 0,
                ],
            });
        },
    });
    const { chain } = useNetwork();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const cart = useSelector((state) => state.cart);
    const user = useSelector((state) => state.user);
    const price = useSelector((state) => state.price);

    const location = useLocation();

    const {
        data: nft,
        refetch: refetchNft,
        error,
    } = useQuery({
        queryKey: ['nftDetails', id],
        queryFn: () =>
            getNFTDetails(id).then((res) => {
                setTimeout(() => {
                    dispatch(toggleLoader(false));
                }, 300);
                console.log("res", res.data.data);
                setImageUrl(
                    res.data.data?.sNftCloudinaryLink ||
                    `${res.data.data?.bIsMigrated ? CONFIG.sPinataIpfsUri : CONFIG.sFilebaseIpfsUri}${res.data.data?.sImageHash}`
                );

                return res.data.data;
            }),

        retry: false,
    });

    const { data: nftActivity, refetch: refetchActivity } = useQuery({
        queryKey: ['nftActivity'],
        queryFn: () => getNFTActivity(id).then((res) => res.data.data),
        enabled: !!nft,
        cacheTime: 0,
    });

    const { data: bidActivity, refetch: refetchBidActivity } = useQuery({
        queryKey: ['bidActivity'],
        queryFn: () => getBidActivity(id).then((res) => res.data.data),
        enabled: !!nft,
    });

    const { mutate: mutateLike } = useMutation({
        mutationFn: (id) => likeNft(id),
        onSuccess: (data) => {
            refetchNft();
        },
        onError: (error) => {
            setIsLike(!isLike);
            notify('error', error.data.message || 'Something went wrong!');
        },
    });

    const { refetch: refetchCart } = useQuery({
        queryKey: ['cartData'],
        queryFn: () =>
            getCart().then((res) => {
                dispatch(updateCart(res.data.data));
                return res.data.data;
            }),
        enabled: false,
        retry: false,
    });

    const { mutate: mutateUnLike } = useMutation({
        mutationFn: (id) => unLikeNft(id),
        onSuccess: (data) => {
            refetchNft();
        },
        onError: (error) => {
            setIsLike(!isLike);
            notify('error', error.data.message || 'Something went wrong!');
        },
    });

    const { mutate: mutateCart } = useMutation({
        mutationFn: (data) => addToCart(data),
        onSuccess: (data) => {
            notify('success', data.data.message);
            refetchCart();
        },
        onError: (error) => {
            notify('error', error.data.message || 'Something went wrong!');
        },
    });

    const {
        data,
        write: writeClaimNft,
        isLoading: isLoadingClaimNft,
    } = useContractWrite({
        address: _contracts_web3.addresses[nft?.nChainId]?.media,
        abi: _contracts_web3.abis.media,
        functionName: 'claim',
        account: address,
        onError(error) {
            notify('error', parseRevertReason(error.shortMessage));
        },
    });

    const { isLoading: isClaimNftLoading, isSuccess: isClaimNFtSuccess } =
        useWaitForTransaction({
            hash: data?.hash,
        });

    useEffect(() => {
        if (isClaimNFtSuccess) {
            refetchNft();
            refetchActivity();
            refetchBidActivity();
            notify('success', 'Claimed successfully');
        }
    }, [isClaimNFtSuccess]);

    useEffect(() => {
        if (
            address &&
            address.toLowerCase() === nft?.oCurrentOwner?.sWalletAddress
        ) {
            setIsOwner(true);
        } else {
            setIsOwner(false);
        }

        if (nft?.aTransaction?.[0]?.eBidStatus ===
            'List') {
            setIsOnSale(true)
        }

        setIsLike(nft?.aFavorite?.includes(getCookie('_userId')));
    }, [nft]);

    useEffect(() => {
        if (cart.length) {
            cart?.forEach((item) => {
                if (item?.iNFT === id) {
                    setIsInCart(true);
                }
            });
        }
    }, [cart]);

    useEffect(() => {
        if (
            address &&
            address.toLowerCase() === nft?.oCurrentOwner?.sWalletAddress
        ) {
            setIsOwner(true);
        } else {
            setIsOwner(false);
        }
        setIsLike(nft?.aFavorite?.includes(getCookie('_userId')));
        refetchNft();
    }, [user]);

    if (error) navigate('/404');

    function getUsdPrice(cryptoPrice, cryptoSymbol) {
        const convertedPrice = cryptoPrice * price?.[cryptoSymbol];
        return convertedPrice;
    }

    useEffect(() => {
        if (nftActivity?.length > 0)
            nftActivity?.forEach((activity) => {
                if (
                    activity?.eBidStatus === 'List' &&
                    activity?.sCancelStatus === 0
                ) {
                    setIsOnSale(true);
                    setSaleQuantity(activity?.nSaleQuantity);
                    setEBidStatus(activity?.eBidStatus);
                }
            });
        else {
            setIsOnSale(false);
            setSaleQuantity(0);
        }
    }, [nftActivity]);

    const handleError = (e) => {
        console.log(nft?.sImageHash);
        setImageUrl(
            `${CONFIG.sEpikoIpfsUri}${nft?.sImageHash}`
        );
    }
    return (
        <section>
            <div className='container section-space-small'>
                <div className='flex gap-20 mb-10 3xl:gap-14 xl:gap-10 lg:gap-8 md:flex-col'>
                    <div
                        className={`w-5/12 lg:w-2/5 md:w-full ${nft?.eType === 'video' ? 'cursor-pointer' : ''
                            }`}
                        onClick={() => setIsVideoPopupOpen(true)}
                    >
                        <div
                            className={`relative overflow-hidden rounded-2xl pt-[100%] ${nft?.eType === 'model'
                                ? 'aspect-w-1 aspect-h-1'
                                : ''
                                }`}
                        >
                            {nft?.sImageHash && nft?.eType === 'model' ? (
                                <div className='absolute top-0 left-0 w-full h-full object-cover object-center'>
                                    <model-viewer
                                        alt={nft?.sName}
                                        src={imageUrl}
                                        ar
                                        poster={imageUrl}
                                        shadow-intensity='1'
                                        camera-controls
                                        ref={(ref) => {
                                            modelRef.current = ref;
                                        }}
                                        touch-action='pan-y'
                                        class='w-full h-full'
                                        autoplay
                                        onError={handleError}
                                    ></model-viewer>
                                </div>
                            ) : nft?.eType === 'image' ? (
                                <img
                                    src={imageUrl}
                                    alt='NftDetailImg'
                                    className='absolute top-0 left-0 w-full h-full object-cover object-center'
                                    onError={handleError}
                                />
                            ) : (
                                <>
                                    <video
                                        src={imageUrl}
                                        alt='NftDetailImg'
                                        className='absolute top-0 left-0 w-full h-full object-cover object-center'
                                        onError={handleError}
                                    />
                                    <div className='absolute right-5 bottom-5 p-6 bg-black/60 rounded-full lg:p-4'>
                                        <img src={PlayIcon} alt='PlayIcon' />
                                    </div>
                                    <VideoModel
                                        isPopupOpen={isVideoPopupOpen}
                                        setIsPopupOpen={setIsVideoPopupOpen}
                                        videoUrl={imageUrl}
                                    />
                                </>
                            )}
                            {nft?.aTransaction?.[0]?.eBidStatus ===
                                'Timed Auction' && (
                                    <Timer
                                        epochTime={nft?.aTransaction?.[0].nEndTime}
                                        setStopTimer={setStopTimer}
                                    />
                                )}
                        </div>
                    </div>
                    <div className='flex-1'>
                        <div className='flex justify-between'>
                            <button
                                className='group btn-border'
                                onClick={(e) => goBack(navigate, location)}
                            >
                                <img
                                    src={ArrowIcon}
                                    alt='ArrowIcon'
                                    className='rotate-180 invert mr-2 group-hover:invert-0'
                                />
                                Back
                            </button>
                            <button
                                className='bg-dark-10 rounded-xl text-lg font-bold py-2 px-5 inline-flex items-center md:text-md sm:text-sm'
                                onClick={() => {
                                    if (!address)
                                        return notify(
                                            'error',
                                            'Please connect your wallet!'
                                        );

                                    isLike
                                        ? mutateUnLike(nft._id)
                                        : mutateLike(nft._id);

                                    setIsLike(!isLike);
                                }}
                            >
                                <span className='bg-black rounded-full mr-2 p-2'>
                                    <img
                                        src={isLike ? LikeIcon : LikeBlankIcon}
                                        alt='LikeIcon'
                                        className='w-[18px]'
                                    />
                                </span>
                                {nft?.aFavorite.length} Likes
                            </button>
                        </div>
                        <h1 className='main-heading-h1 text-black font-darker mt-5'>
                            {nft?.sName}
                        </h1>
                        <div className='flex gap-3 mt-3 xs:flex-wrap'>
                            <span className='text-base font-bold font-darker text-dark-90 bg-dark-10 rounded-xl inline-block px-5 pt-1.5 pb-2.5 sm:text-sm sm:pt-1 sm:pb-2 sm:px-3'>
                                {nft?.oCollection?.sName}
                            </span>
                            <span className='text-base font-bold font-darker text-dark-90 bg-dark-10 rounded-xl inline-block px-5 pt-1.5 pb-2.5 sm:text-sm sm:pt-1 sm:pb-2 sm:px-3'>
                                {nft?.oCategory?.sName}
                            </span>
                            <span className='text-base font-bold font-darker text-dark-90 bg-dark-10 rounded-xl inline-block px-5 pt-1.5 pb-2.5 sm:text-sm sm:pt-1 sm:pb-2 sm:px-3'>
                                Brand : {nft?.oBrand?.sBrandName}
                            </span>
                            {isOnSale ? <span className='text-base font-bold font-darker text-dark-90 bg-dark-10 rounded-xl inline-block px-5 pt-1.5 pb-2.5 sm:text-sm sm:pt-1 sm:pb-2 sm:px-3'>
                                <img
                                    src={CloneIcon}
                                    alt='CloneIcon'
                                    className='mr-2 inline-block -my-1'
                                />
                                On Sale : {isOwner && !isOnSale
                                    ? nft?.nQuantity
                                    : saleQuantity || nft?.nQuantity}
                            </span> : null}
                            <span className='text-base font-bold font-darker text-dark-90 bg-dark-10 rounded-xl inline-block px-5 pt-1.5 pb-2.5 sm:text-sm sm:pt-1 sm:pb-2 sm:px-3'>
                                <img
                                    src={CloneIcon}
                                    alt='CloneIcon'
                                    className='mr-2 inline-block -my-1'
                                />
                                Total :  {nft?.nQuantity + saleQuantity}
                            </span>
                        </div>
                        {nft?.aTransaction?.length &&
                            nft?.aTransaction?.[0]?.eBidStatus === 'List' ? (
                            <div className='flex gap-x-10 mt-8 xl:gap-x-8 lg:gap-x-5'>
                                <div>
                                    <p className='text-lg text-black font-semibold font-darker'>
                                        Selling Price
                                    </p>
                                    <div className='flex items-end gap-x-5 lg:flex-wrap'>
                                        <h4 className='text-3xl font-black font-darker 3xl:text-2.5xl xl:text-2xl'>
                                            {nft?.aTransaction?.[0]?.nBidPrice || nftActivity?.[0]?.nBidPrice}{' '}
                                            {nftActivity?.[0]?.sTokenSymbol ||
                                                chainSymbolById(nft?.nChainId)}
                                        </h4>

                                        <p className='text-2xl font-bold font-darker text-gray 3xl:text-xl 3xl:pb-1 xl:text-lg'>
                                            ~
                                            {getUsdPrice(
                                                nft?.aTransaction?.[0]?.nBidPrice || nftActivity?.[0]?.nBidPrice,
                                                nftActivity?.[0]
                                                    ?.sTokenSymbol ||
                                                chainSymbolById(
                                                    nft?.nChainId
                                                )
                                            ).toFixed(2)}{' '}
                                            USD
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ) : nft?.aTransaction?.[0]?.eBidStatus ===
                            'Timed Auction' &&
                            nft?.aTransaction?.[0].nEndTime > moment().unix() ? (
                            <div className='flex gap-x-10 mt-8 xs:flex-col xs:gap-y-2 xl:gap-x-8 lg:gap-x-5'>
                                <div>
                                    <p className='text-lg text-black font-semibold font-darker'>
                                        Base Price
                                    </p>
                                    <div className='flex items-end gap-x-5 flex-wrap'>
                                        <h4 className='text-3xl font-black font-darker 3xl:text-2.5xl xl:text-2xl'>
                                            {formatNumber(
                                                nft?.aTransaction?.[0].nBidPrice
                                            )}{' '}
                                            {nftActivity?.[0]?.sTokenSymbol ||
                                                chainSymbolById(nft?.nChainId)}
                                        </h4>

                                        <p className='text-2xl font-bold font-darker text-gray 3xl:text-xl 3xl:pb-1 xl:text-lg'>
                                            ~
                                            {getUsdPrice(
                                                nft?.aTransaction?.[0]
                                                    .nBidPrice,
                                                nftActivity?.[0]
                                                    ?.sTokenSymbol ||
                                                chainSymbolById(
                                                    nft?.nChainId
                                                )
                                            ).toFixed(2)}{' '}
                                            USD
                                        </p>
                                    </div>
                                </div>
                                <div>
                                    <p className='text-lg text-black font-semibold font-darker'>
                                        Last Bid
                                    </p>
                                    <div className='flex items-end gap-x-5 flex-wrap'>
                                        <h4 className='text-3xl font-black font-darker 3xl:text-2.5xl xl:text-2xl'>
                                            {formatNumber(
                                                nftActivity?.[0]?.nBidPrice
                                            )}{' '}
                                            {nftActivity?.[0]?.sTokenSymbol ||
                                                chainSymbolById(nft?.nChainId)}
                                        </h4>
                                        <p className='text-2xl font-bold font-darker text-gray 3xl:text-xl 3xl:pb-1 xl:text-lg'>
                                            ~
                                            {getUsdPrice(
                                                nftActivity?.[0]?.nBidPrice,
                                                nftActivity?.[0]
                                                    ?.sTokenSymbol ||
                                                chainSymbolById(
                                                    nft?.nChainId
                                                )
                                            ).toFixed(2)}{' '}
                                            USD
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ) : null}
                        <div className='mt-4 inline-flex flex-wrap gap-3 w-full'>
                            {isOwner &&
                                (!nftActivity?.[0]?.eBidStatus ||
                                    nftActivity?.[0]?.eBidStatus === 'Mint' ||
                                    nftActivity?.[0]?.eBidStatus ===
                                    'Sale Cancelled' ||
                                    nftActivity?.[0]?.eBidStatus ===
                                    'Auction Cancelled' ||
                                    (nftActivity?.[0]?.eBidStatus === 'Sold' && !isOnSale) ||
                                    (nftActivity?.[0]?.eBidStatus === 'Transfer' && nft?.aTransaction?.[0]?.eBidStatus !== 'List') ||
                                    nftActivity?.[0]?.eBidStatus === 'Claimed' ||
                                    (nftActivity?.[0]?.eBidStatus === 'Burn' && nft?.aTransaction?.[0]?.eBidStatus !== 'List')) ? (
                                <>
                                    <button
                                        className='btn-secondary'
                                        onClick={() =>
                                            setIsHideNftPopupOpen(true)
                                        }
                                    >
                                        HIDE NFT
                                    </button>
                                    <button
                                        className='btn-border'
                                        onClick={() =>
                                            setIsTransferNftPopupOpen(true)
                                        }
                                    >
                                        TRANSFER NFT
                                    </button>
                                    <button
                                        className='btn-border'
                                        onClick={() =>
                                            setIsPutOnSalePopupOpen(true)
                                        }
                                    >
                                        PUT ON SALE
                                    </button>
                                    <button
                                        className='btn-border'
                                        onClick={() =>
                                            setIsBurnNftPopupOpen(true)
                                        }
                                    >
                                        BURN NFT
                                    </button>
                                </>
                            ) : isOwner &&
                                nftActivity?.[0]?.eBidStatus !== 'Bid' &&
                                (nftActivity?.[0]?.eBidStatus === 'Bid' ||
                                    nftActivity?.[0]?.eBidStatus ===
                                    'Timed Auction' ||
                                    nftActivity?.[0]?.eBidStatus === 'List' ||
                                    isOnSale) ? (
                                <>
                                    <button
                                        className='btn-secondary mr-3'
                                        onClick={(e) =>
                                            setIsRemoveFromSalePopupOpen(true)
                                        }
                                    >
                                        REMOVE FROM{' '}
                                        {eBidStatus === 'List' ? 'SALE' : 'AUCTION'}
                                    </button>
                                    <button
                                        className='btn-border'
                                        onClick={() =>
                                            setIsTransferNftPopupOpen(true)
                                        }
                                    >
                                        TRANSFER NFT
                                    </button>
                                    <button
                                        className='btn-border'
                                        onClick={() =>
                                            setIsBurnNftPopupOpen(true)
                                        }
                                    >
                                        BURN NFT
                                    </button>
                                </>
                            ) : nft?.aTransaction?.[0]?.eBidStatus ===
                                'List' && isOnSale ? (
                                <>
                                    <button
                                        className='btn-secondary mr-3'
                                        onClick={() => {
                                            if (!address)
                                                return notify(
                                                    'error',
                                                    'Please connect your wallet'
                                                );
                                            setIsBuyNowPopupOpen(true);
                                        }}
                                    >
                                        BUY NOW
                                    </button>
                                    {nft?.aTransaction?.[0]?.eBidStatus ===
                                        'List' && !isInCart ? (
                                        <button
                                            className='btn-border mr-3'
                                            onClick={() => {
                                                if (!address)
                                                    return notify(
                                                        'error',
                                                        'Please connect your wallet'
                                                    );

                                                mutateCart({
                                                    sNftId: id,
                                                    nQuantity: 1,
                                                    nChainId: nft?.nChainId,
                                                    sPaymentMode:
                                                        nftActivity?.[0]
                                                            ?.sTokenSymbol ||
                                                        chainSymbolById(
                                                            nft?.nChainId
                                                        ),
                                                });
                                            }}
                                        >
                                            ADD TO CART
                                        </button>
                                    ) : (
                                        <button
                                            className='btn-border mr-3'
                                            onClick={(e) => {
                                                e.preventDefault();
                                                navigate('/my-cart');
                                            }}
                                        >
                                            VIEW CART
                                        </button>
                                    )}
                                </>
                            ) : nft?.aTransaction?.[0]?.eBidStatus ===
                                'Timed Auction' &&
                                nft?.aTransaction?.[0].nEndTime >
                                moment().unix() &&
                                !stopTimer &&
                                !isOwner ? (
                                <button
                                    className='btn-secondary mr-3'
                                    onClick={() => {
                                        if (!address)
                                            return notify(
                                                'error',
                                                'Please connect your wallet'
                                            );
                                        setIsBidNowPopupOpen(true);
                                    }}
                                >
                                    BID NOW
                                </button>
                            ) : (
                                isConnected &&
                                // nftActivity?.[0]?.eBidStatus === 'Bid' &&
                                moment().unix() >=
                                nft?.aTransaction?.[0]?.nEndTime &&
                                !isOwner &&
                                bidActivity?.[0]?.aFrom?.sUserName ===
                                user?.userName && (
                                    <button
                                        className='btn-secondary mr-3'
                                        onClick={async () => {
                                            if (!address)
                                                return notify(
                                                    'error',
                                                    'Please connect your wallet'
                                                );
                                            if (chain.id !== nft?.nChainId) {
                                                await switchNetwork(
                                                    nft?.nChainId
                                                );
                                            } else {
                                                writeClaimNft({
                                                    args: [
                                                        nft?.sCollectionAddress,
                                                        nft?.nTokenId,
                                                        nft?.oCurrentOwner
                                                            ?.sWalletAddress,
                                                        nft?.aTransaction?.[0]
                                                            ?.nQuantity,
                                                        nft?.oBrand
                                                            ?.matchedTierInfo?.[0]
                                                            ?.tierDetails
                                                            ?.nTierCommission ||
                                                        0,
                                                    ],
                                                });
                                            }
                                        }}
                                        disabled={
                                            isClaimNftLoading ||
                                            isLoadingClaimNft
                                        }
                                    >
                                        {(isClaimNftLoading ||
                                            isLoadingClaimNft) && (
                                                <ButtonLoader />
                                            )}
                                        Claim NFT
                                    </button>
                                )
                            )}
                        </div>
                        <div className='bg-dark-10 p-5 inline-flex rounded-2xl my-8 xs:flex-col'>
                            <div className='border-r-2 border-dark-30 pr-5 mr-5 xs:border-r-0 xs:m-0 xs:pr-0 xs:pb-3'>
                                <p className='text-lg text-black font-semibold font-darker'>
                                    Creator
                                </p>
                                <div className='flex gap-3 mt-2 items-center'>
                                    <div className='flex-shrink-0 relative w-14 h-14 lg:w-12 lg:h-12'>
                                        {nft?.oPostedBy.sProfilePicUrl ? (
                                            <img
                                                src={
                                                    nft?.oPostedBy
                                                        .sProfilePicUrl
                                                }
                                                alt='UserImg'
                                                className='absolute left-0 top-0 w-full h-full rounded-full object-cover object-center'
                                                onError={(e) => {
                                                    e.target.onerror = null; // Prevents infinite loop in case of continuous errors
                                                    e.target.style.display =
                                                        'block'; // Adjust as needed
                                                    e.target.src =
                                                        'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs='; // Transparent GIF
                                                }}
                                            />
                                        ) : (
                                            <div className='absolute left-0 top-0 rounded-full w-full h-full object-cover object-center bg-gradient'></div>
                                        )}
                                    </div>
                                    <div className='font-darker'>
                                        <Link
                                            to={`/user/${nft?.oPostedBy.sUserName}`}
                                            className='group'
                                        >
                                            <h6 className='text-xl text-black font-extrabold duration-300 sm:text-lg group-hover:text-dark-80'>
                                                {nft?.oPostedBy.sUserName}
                                            </h6>
                                            <p className='text-dark-90 text-base font-bold truncate duration-300  group-hover:text-dark-80'>
                                                {nft?.oPostedBy &&
                                                    trimEthereumAddress(
                                                        nft?.oPostedBy
                                                            ?.sWalletAddress,
                                                        6
                                                    )}
                                            </p>
                                        </Link>
                                    </div>
                                    {nft?.aCollaboratorData?.length ? <div
                                        className='mb-5 cursor-pointer'
                                        onClick={() =>
                                            setIsCollaboratorsPopupOpen(true)
                                        }
                                    >
                                        <img
                                            src={CollapseIcon}
                                            alt='CollapseIcon'
                                        />
                                    </div> : null}
                                </div>
                            </div>
                            <div>
                                <p className='text-lg text-black font-semibold font-darker'>
                                    Owner
                                </p>
                                <div className='flex gap-3 mt-2 items-center'>
                                    <div className='flex-shrink-0 relative w-14 h-14 lg:w-12 lg:h-12'>
                                        {nft?.oCurrentOwner?.sProfilePicUrl ? (
                                            <img
                                                src={
                                                    nft?.oCurrentOwner
                                                        .sProfilePicUrl
                                                }
                                                alt='UserImg'
                                                className='absolute left-0 top-0 w-full h-full rounded-full object-cover object-center'
                                                onError={(e) => {
                                                    e.target.onerror = null; // Prevents infinite loop in case of continuous errors
                                                    e.target.style.display =
                                                        'block'; // Adjust as needed
                                                    e.target.src =
                                                        'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs='; // Transparent GIF
                                                }}
                                            />
                                        ) : (
                                            <div className='absolute left-0 top-0 rounded-full w-full h-full object-cover object-center bg-gradient'></div>
                                        )}
                                    </div>
                                    <div className='font-darker'>
                                        <Link
                                            to={`/user/${nft?.oCurrentOwner?.sUserName}`}
                                            className='group'
                                        >
                                            <h6 className='text-xl text-black font-extrabold sm:text-lg duration-300 group-hover:text-dark-80'>
                                                {nft?.oCurrentOwner?.sUserName}
                                            </h6>
                                            <p className='text-dark-90 text-base font-bold truncate duration-300 group-hover:text-dark-80'>
                                                {nft?.oCurrentOwner &&
                                                    trimEthereumAddress(
                                                        nft?.oCurrentOwner
                                                            ?.sWalletAddress,
                                                        6
                                                    )}
                                            </p>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className=''>
                            <h6 className='text-lg text-black font-darker font-semibold'>
                                Description
                            </h6>
                            <p className='text-base text-dark-90 mt-3 md:text-sm'>
                                {nft?.sNftdescription}
                            </p>
                        </div>
                    </div>
                </div>
                <div
                    className={`flex mb-10 gap-20 3xl:gap-14 xl:gap-10 lg:gap-8  md:flex-col ${!isPropertiesShow || !isContentShow ? 'items-start' : ''
                        }`}
                >
                    {nft?.aProperties?.length ? <div className='border border-dark-30 rounded-2xl p-5 px-6 w-5/12 lg:w-2/5 md:w-full overflow-auto'>
                        <div
                            className='flex cursor-pointer justify-between '
                            onClick={() =>
                                setIsPropertiesShow(!isPropertiesShow)
                            }
                        >
                            <h6 className='text-lg bg-gradient text-transparent bg-clip-text font-bold inline-block md:text-md'>
                                Properties
                            </h6>
                            <img
                                src={CollapseIcon}
                                alt='CollapseIcon'
                                className={isPropertiesShow ? 'rotate-180' : ''}
                            />
                        </div>
                        {isPropertiesShow &&
                            <div div className='flex gap-4 flex-wrap border-t border-dark-40 pt-5 mt-3'>
                                {nft.aProperties.map((property, index) =>
                                (
                                    <div className='bg-dark-10 text-center rounded-xl p-3 w-[calc(100%/3-11px)] 2xl:w-[calc(100%/2-8px)] xs:w-full' key={index}>
                                        {/* <p className='text-base font-bold text-dark-70 font-darker'>
                                            Background
                                        </p> */}
                                        <h5 className='font-darker font-extrabold text-xl text-black'>
                                            {property.trait_type}
                                        </h5>
                                        <p className='text-base font-bold text-dark-70 font-darker'>
                                            {property.value}
                                        </p>
                                    </div>
                                )
                                )}
                            </div>
                        }
                    </div> : null}
                    {isOwner && nft.sUnlockableContent ? <div className='border border-dark-40 rounded-2xl p-5 px-6 ml-auto flex-1 w-1/2  md:w-full'>
                        <div
                            className='flex cursor-pointer justify-between '
                            onClick={() => setIsContentShow(!isContentShow)}
                        >
                            <h6 className='text-lg bg-gradient text-transparent bg-clip-text font-bold inline-block md:text-md'>
                                Unlockable content
                            </h6>
                            <img
                                src={CollapseIcon}
                                alt='CollapseIcon'
                                className={isContentShow ? 'rotate-180' : ''}
                            />
                        </div>
                        {isContentShow && (
                            <div className='border-t border-dark-40 pt-5 mt-3'>
                                <p className='flex items-center gap-3 text-base text-dark-90'>
                                    <img src={LockIcon} alt='LockIcon' />{' '}
                                    Includes Unlockable Content
                                </p>
                                <p className='text-base text-dark-90 mb-5'>
                                    {nft?.sUnlockableContent}
                                </p>
                            </div>
                        )}
                    </div> : null}
                </div>
                {bidActivity?.length ? (
                    <div className='mb-4'>
                        <h3 className='text-2xl text-black font-bold md:text-lg'>
                            Placed Bids
                        </h3>
                    </div>
                ) : null}
                <div className='flex gap-20 3xl:gap-14 xl:gap-10 lg:gap-8  md:flex-col'>
                    {bidActivity?.length ? (
                        <div className='border border-dark-30 rounded-2xl p-5 px-6 w-5/12 lg:w-2/5 md:w-full overflow-auto'>
                            <table className='table-main'>
                                <thead>
                                    <tr>
                                        <th className='table-th'>
                                            <span>Placed By</span>
                                        </th>
                                        <th className='table-th'>
                                            <span>Date</span>
                                        </th>
                                        <th className='table-th'>
                                            <span>Amount</span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {bidActivity?.length
                                        ? bidActivity?.map((bid) => {
                                            return (
                                                <tr key={bid.dCreatedAt}>
                                                    <td className='table-td'>
                                                        <Link
                                                            to={`/user/${bid.aFrom.sUserName}`}
                                                        >
                                                            {
                                                                bid.aFrom
                                                                    .sUserName
                                                            }
                                                        </Link>
                                                    </td>
                                                    <td className='table-td'>
                                                        {parseDate(
                                                            bid.dCreatedAt
                                                        )}
                                                    </td>
                                                    <td className='table-td'>
                                                        {formatNumber(
                                                            bid.nBidPrice
                                                        )}{' '}
                                                        {bid.sTokenSymbol ||
                                                            chainSymbolById(
                                                                nft?.nChainId
                                                            )}
                                                    </td>
                                                </tr>
                                            );
                                        })
                                        : null}
                                </tbody>
                            </table>
                        </div>
                    ) : (
                        <div className='w-5/12 lg:w-2/5 md:hidden'>{null}</div>
                    )}
                    <div className='border border-dark-40 rounded-2xl p-5 px-6 ml-auto flex-1 w-1/2  md:w-full'>
                        <div className='border-b border-dark-40 pb-3 mb-3'>
                            <h6 className='text-lg bg-gradient text-transparent bg-clip-text font-bold inline-block md:text-md'>
                                NFT Details
                            </h6>
                        </div>
                        <div className='flex justify-between py-2.5'>
                            <p className='text-base text-black font-bold md:text-sm'>
                                Contract Address
                            </p>
                            <p className='text-base text-black md:text-sm'>
                                {nft?.sCollectionAddress && (
                                    <a
                                        href={`${CHAINS_WEB3[nft?.nChainId]
                                            .blockExplorerUrls
                                            }/address/${nft?.sCollectionAddress}`}
                                        target='_blank'
                                        rel='noreferrer'
                                    >
                                        {trimEthereumAddress(
                                            nft?.sCollectionAddress,
                                            6
                                        )}
                                    </a>
                                )}
                            </p>
                        </div>
                        <div className='flex justify-between py-2.5'>
                            <p className='text-base text-black font-bold md:text-sm'>
                                Token
                            </p>
                            <p className='text-base text-black md:text-sm'>
                                {nft?.nTokenId && (
                                    <a
                                        href={`${CHAINS_WEB3[nft?.nChainId]
                                            .blockExplorerUrls
                                            }/tx/${nft?.sTransactionHash}`}
                                        target='_blank'
                                        rel='noreferrer'
                                    >
                                        ID {formatNumber(nft?.nTokenId)}
                                    </a>
                                )}
                            </p>
                        </div>
                        <div className='flex justify-between py-2.5'>
                            <p className='text-base text-black font-bold md:text-sm'>
                                Token Standard
                            </p>
                            <p className='text-base text-black md:text-sm'>
                                {nft?.sCollectionType === 'ERC721'
                                    ? 'ERC-721'
                                    : 'ERC-1155'}
                            </p>
                        </div>
                        <div className='flex justify-between py-2.5'>
                            <p className='text-base text-black font-bold md:text-sm'>
                                Chain
                            </p>
                            <p className='text-base text-black md:text-sm'>
                                {nft?.nChainId &&
                                    CHAINS_WEB3[nft?.nChainId].chainName}
                            </p>
                        </div>
                    </div>
                </div>
                <div className='mt-8'>
                    <div className='border border-dark-30 rounded-2xl p-5 px-6 overflow-auto max-h-80'>
                        <table className='table-main'>
                            <thead>
                                <tr>
                                    <th className='table-th'>
                                        <span>Event</span>
                                    </th>
                                    <th className='table-th'>
                                        <span>Unit Price</span>
                                    </th>
                                    <th className='table-th'>
                                        <span>Quantity</span>
                                    </th>
                                    <th className='table-th'>
                                        <span>From</span>
                                    </th>
                                    <th className='table-th'>
                                        <span>To</span>
                                    </th>
                                    <th className='table-th'>
                                        <span>Date</span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {nftActivity?.map((activity) => {
                                    return activity?.eBidStatus !== 'Bid' ? (
                                        <tr key={activity?.dCreatedAt}>
                                            <td className='table-td'>
                                                {activity?.eBidStatus}
                                            </td>
                                            <td className='table-td'>
                                                {activity?.nBidPrice > 0
                                                    ? formatNumber(
                                                        activity.nBidPrice
                                                    ) +
                                                    ' ' +
                                                    (activity.sTokenSymbol ||
                                                        chainSymbolById(
                                                            nft?.nChainId
                                                        ))
                                                    : ''}
                                            </td>
                                            <td className='table-td'>
                                                {formatNumber(
                                                    activity?.nQuantity
                                                )}
                                            </td>
                                            <td className='table-td'>
                                                <Link
                                                    to={`/user/${activity?.eBidStatus ===
                                                        'Sold'
                                                        ? activity?.aTo
                                                            ?.sUserName
                                                        : activity?.aFrom
                                                            ?.sUserName
                                                        }`}
                                                    className='group'
                                                    onClick={(e) => {
                                                        if (
                                                            activity?.eBidStatus ===
                                                            'Mint'
                                                        )
                                                            e.preventDefault();
                                                    }}
                                                >
                                                    {activity?.eBidStatus ===
                                                        'Mint'
                                                        ? 'Null Address'
                                                        : activity?.eBidStatus ===
                                                            'Claimed' ||
                                                            activity?.eBidStatus ===
                                                            'Sold'
                                                            ? activity?.aTo
                                                                ?.sUserName
                                                            : activity?.aFrom
                                                                ?.sUserName}
                                                </Link>
                                            </td>
                                            <td className='table-td'>
                                                <Link
                                                    to={`/user/${activity?.eBidStatus ===
                                                        'Mint'
                                                        ? activity?.aFrom
                                                            ?.sUserName
                                                        : activity?.eBidStatus ===
                                                            'Claimed' ||
                                                            activity?.eBidStatus ===
                                                            'Sold'
                                                            ? activity?.aFrom
                                                                ?.sUserName
                                                            : activity?.aTo
                                                                ?.sUserName
                                                        }`}
                                                    className='group'
                                                >
                                                    {activity?.eBidStatus ===
                                                        'Mint'
                                                        ? activity?.aFrom
                                                            ?.sUserName
                                                        : activity?.eBidStatus ===
                                                            'Burn'
                                                            ? 'Null Address'
                                                            : activity?.eBidStatus ===
                                                                'Claimed' ||
                                                                activity?.eBidStatus ===
                                                                'Sold'
                                                                ? activity?.aFrom
                                                                    ?.sUserName
                                                                : activity?.aTo
                                                                    ?.sUserName ||
                                                                '-------------'}
                                                </Link>
                                            </td>
                                            <td className='table-td'>
                                                {parseDate(activity.dCreatedAt)}
                                            </td>
                                        </tr>
                                    ) : null;
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {
                nft && nftActivity && (
                    <>
                        {isRemoveFromSalePopupOpen && (
                            <RemoveFromSalePopup
                                isPopupOpen={isRemoveFromSalePopupOpen}
                                setIsPopupOpen={setIsRemoveFromSalePopupOpen}
                                nftAddress={nft?.sCollectionAddress}
                                nftId={nft?.nTokenId}
                                nftChainId={nft?.nChainId}
                                refetchNft={refetchNft}
                                refetchActivity={refetchActivity}
                                method={
                                    nft?.aTransaction?.[0]?.eBidStatus === 'List'
                                        ? 'withdrawSale'
                                        : 'withdrawAuction'
                                }
                            />
                        )}
                        {isHideNftPopupOpen && (
                            <RemoveFromSalePopup
                                isHide={true}
                                isPopupOpen={isHideNftPopupOpen}
                                setIsPopupOpen={setIsHideNftPopupOpen}
                                nftId={nft?._id}
                            />
                        )}
                        {isTransferNftPopupOpen && (
                            <TransferNFTPopup
                                isPopupOpen={isTransferNftPopupOpen}
                                setIsPopupOpen={setIsTransferNftPopupOpen}
                                nftId={nft?.nTokenId}
                                nftChainId={nft?.nChainId}
                                refetchNft={refetchNft}
                                refetchActivity={refetchActivity}
                                nftType={nft?.sCollectionType}
                                nftAddress={nft?.sCollectionAddress}
                                isRedirect={nft?.nQuantity === 1}
                                nftQuantity={nft?.nQuantity}
                            />
                        )}
                        {isPutOnSalePopupOpen && (
                            <PutNftOnSalePopup
                                isPopupOpen={isPutOnSalePopupOpen}
                                setIsPopupOpen={setIsPutOnSalePopupOpen}
                                nftQuantity={nft?.nQuantity}
                                nftAddress={nft?.sCollectionAddress}
                                nftId={nft?.nTokenId}
                                nftChainId={nft?.nChainId}
                                refetchNft={refetchNft}
                                refetchActivity={refetchActivity}
                                nftType={nft?.sCollectionType}
                                owner={nft?.oCurrentOwner?.sWalletAddress}
                            />
                        )}
                        {isBurnNftPopupOpen && (
                            <BurnNftPopup
                                isPopupOpen={isBurnNftPopupOpen}
                                setIsPopupOpen={setIsBurnNftPopupOpen}
                                nftQuantity={nft?.nQuantity}
                                nftAddress={nft?.sCollectionAddress}
                                nftId={nft?.nTokenId}
                                nftChainId={nft?.nChainId}
                                nftType={nft?.sCollectionType}
                                owner={nft?.oCurrentOwner?.sWalletAddress}
                            />
                        )}
                        {isBuyNowPopupOpen && (
                            <BuyNowPopup
                                isPopupOpen={isBuyNowPopupOpen}
                                setIsPopupOpen={setIsBuyNowPopupOpen}
                                currentOwner={nft?.oCurrentOwner?.sWalletAddress}
                                nftQuantity={saleQuantity}
                                nftAddress={nft?.sCollectionAddress}
                                nftId={nft?.nTokenId}
                                nftChainId={nft?.nChainId}
                                nftName={nft?.sName}
                                nftPrice={nftActivity?.[0]?.nBidPrice}
                                refetchNft={refetchNft}
                                refetchActivity={refetchActivity}
                                eType={nft?.eType}
                                nftImage={
                                    imageUrl
                                }
                                sTokenSymbol={nftActivity?.[0]?.sTokenSymbol}
                                sTokenAddress={nftActivity?.[0]?.sTokenAddress}
                                commission={
                                    nft?.oBrand?.matchedTierInfo?.[0]?.tierDetails
                                        ?.nTierCommission || 0
                                }
                                setIsOnSale={setIsOnSale}
                            />
                        )}
                        {isBidNowPopupOpen && (
                            <BidNowPopup
                                isPopupOpen={isBidNowPopupOpen}
                                setIsPopupOpen={setIsBidNowPopupOpen}
                                currentOwner={nft?.oCurrentOwner?.sWalletAddress}
                                nftQuantity={nftActivity?.[0]?.nQuantity}
                                nftAddress={nft?.sCollectionAddress}
                                nftId={nft?.nTokenId}
                                nftChainId={nft?.nChainId}
                                nftImage={
                                    imageUrl
                                }
                                nftName={nft?.sName}
                                nftPrice={nftActivity?.[0]?.nBidPrice}
                                sTokenSymbol={nftActivity?.[0]?.sTokenSymbol}
                                sTokenAddress={nftActivity?.[0]?.sTokenAddress}
                                eType={nft?.eType}
                                refetchNft={refetchNft}
                                refetchActivity={refetchActivity}
                                refetchBidActivity={refetchBidActivity}
                            />
                        )}
                        {isCollaboratorsPopupOpen && (
                            <CollaboratorsPopup
                                isPopupOpen={isCollaboratorsPopupOpen}
                                setIsPopupOpen={setIsCollaboratorsPopupOpen}
                                nftDetail={nft}
                            />
                        )}
                    </>
                )
            }
        </section >
    );
}

export default NftDetail;
