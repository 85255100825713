import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { useAccount } from "wagmi";
import { getFollowFollowers } from "../../api/adapters/user";
import { getToken } from "../../utils/cookies.helper";
import { formatNumber } from "../../utils/helper";

// COMPONENTS
import FollowingFollowersPopup from "../Modal/FollowingFollowersPopup";

// IMAGES
import UserIcon from "../../assets/images/user-Icon.svg";
import EditProfilePopup from "../Modal/EditProfilePopup";
import VerifyAccountPopup from "../Modal/VerifyAccountPopup";

const FollowerFollowing = ({
    follower,
    following,
    sUserName,
    aFollowers,
    aFollowing,
    refetchUser,
    isMyProfile = false,
}) => {
    const [isFollowsPopupOpen, setIsFollowsPopupOpen] = useState(false);
    const [isFollowerPop, setIsFollowerPop] = useState();
    const { isConnected } = useAccount();
    const [isVerifyPopupOpen, setIsVerifyPopupOpen] = useState(false);
    const [isEditProfilePopupOpen, setIsEditProfilePopupOpen] = useState(false);

    const { data: followerFollowing, refetch: refetchFollowerFollowing } =
        useQuery({
            queryKey: ["followFollowing", isFollowerPop, following, follower],
            queryFn: () =>
                getFollowFollowers({
                    bFollowers: isFollowerPop,
                    sUserName,
                }).then((res) => {
                    return res.data.data[0];
                }),
            enabled: true,
        });

    return (
        <>
            <div className="flex flex-col items-end md:items-start">
                <div
                    className={`cursor-pointer md:text-left ${isMyProfile ? "text-right " : "text-left"
                        }}`}
                    onClick={() => {
                        if (!getToken() || !isConnected) return;
                        setIsFollowsPopupOpen(true);
                        setIsFollowerPop(false);
                    }}
                >
                    <h4 className="text-3xl text-black font-monserrat font-bold lg:text-2.5xl sm:text-2xl">
                        {formatNumber(following)}
                    </h4>
                    <p className="inline-flex text-dark-60 text-sm mt-3">
                        <img src={UserIcon} alt="UserIcon" className="mr-2" />
                        Following
                    </p>
                </div>
                {/* {isMyProfile && (
                    <button
                        className='btn-secondary mt-3.5'
                        onClick={() => setIsVerifyPopupOpen(true)}
                    >
                        Verify account
                    </button>
                )} */}
            </div>
            <div className="flex flex-col items-end md:items-start">
                <div
                    className={`cursor-pointer md:text-left ${isMyProfile ? "text-right " : "text-left"
                        }}`}
                    onClick={() => {
                        if (!getToken() || !isConnected) return;
                        setIsFollowsPopupOpen(true);
                        setIsFollowerPop(true);
                    }}
                >
                    <h4 className="text-3xl text-black font-monserrat font-bold lg:text-2.5xl sm:text-2xl">
                        {formatNumber(follower)}
                    </h4>
                    <p className="inline-flex text-dark-60 text-sm mt-3">
                        <img src={UserIcon} alt="UserIcon" className="mr-2" />
                        Followers
                    </p>
                </div>

                {isMyProfile && (
                    <button
                        className="btn-border mt-3.5"
                        onClick={() => setIsEditProfilePopupOpen(true)}
                    >
                        Edit profile
                    </button>
                )}
            </div>
            <FollowingFollowersPopup
                isPopupOpen={isFollowsPopupOpen}
                setIsPopupOpen={setIsFollowsPopupOpen}
                isFollowing={isFollowerPop}
                data={followerFollowing?.followers}
                aFollowers={aFollowers}
                aFollowing={aFollowing}
                refetch={refetchUser}
                refetchFollowerFollowing={refetchFollowerFollowing}
            />
            <VerifyAccountPopup
                isPopupOpen={isVerifyPopupOpen}
                setIsPopupOpen={setIsVerifyPopupOpen}
            />
            {isEditProfilePopupOpen ? (
                <EditProfilePopup
                    isPopupOpen={isEditProfilePopupOpen}
                    setIsPopupOpen={setIsEditProfilePopupOpen}
                    refetch={refetchUser}
                />
            ) : null}
        </>
    );
};

export default FollowerFollowing;
