import { useMutation, useQuery } from '@tanstack/react-query';
import { ethers } from 'ethers';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
    useAccount,
    useContractRead,
    useContractWrite,
    useNetwork,
    useSwitchNetwork,
    useWaitForTransaction,
} from 'wagmi';
import { toggleLoader } from '../../redux/actions/loader';

// COMPONENTS
import QuantityControl from '../../components/FormControl/QuantityControl';

// IMAGES
import { Link } from 'react-router-dom';
import { editCart, getCart, removeFromCart } from '../../api/adapters/cart';
import DeleteIcon from '../../assets/images/delete-icon.svg';
import ButtonLoader from '../../components/Loader/ButtonLoader';
import NoDataFound from '../../components/NoDataFound/NoDataFound';
import _contracts_web3 from '../../configs/contracts.web3';
import { CONFIG } from '../../configs/platform.config';
import { updateCart } from '../../redux/actions/cart';
import { notify } from '../../utils/common.helper';
import {
    formatNumber,
    parseRevertReason,
    shortenAddress,
    toEther,
    toWei,
} from '../../utils/helper';

function MyCart() {
    const [quantity, setQuantity] = useState([]);
    const [totalPrice, setTotalPrice] = useState(0);
    const [totalQuantity, setTotalQuantity] = useState(0);
    const [isEnabled, setIsEnabled] = useState(false);
    const [isApproved, setIsApproved] = useState(false);
    const [isEnoughBalance, setIsEnoughBalance] = useState(false);
    const [chainId, setChainId] = useState();
    const [erc20Address, setErc20Address] = useState();
    const [tokenSymbol, setTokenSymbol] = useState();
    const [decimals, setDecimals] = useState(18);
    const [imageUrls, setImageUrls] = useState([]);

    const { chain } = useNetwork();
    const { address } = useAccount();
    const dispatch = useDispatch();
    const modelRef = useRef();
    const { switchNetwork } = useSwitchNetwork({
        onSuccess: () => {
            handleBuyNow();
        },
    });

    const { data: cartData, refetch } = useQuery({
        queryKey: ['cartData'],
        queryFn: () =>
            getCart().then((res) => {
                try {
                    setTimeout(() => {
                        dispatch(toggleLoader(false));
                    }, 300);
                    dispatch(updateCart(res.data.data));
                    setQuantity(
                        Array.from(
                            res.data.data,
                            (item, index) => item.nQuantity
                        )
                    );

                    const decimals =
                        CONFIG.token[
                            res.data.data[0]?.transactions.sTokenSymbol
                        ]?.decimals || 18;

                    setDecimals(decimals);

                    let price = ethers.BigNumber.from(0);
                    const priceArray = Array.from(
                        res.data.data,
                        (item, index) => Number(item.transactions.nBidPrice)
                    );
                    const quantityArray = Array.from(
                        res.data.data,
                        (item, index) => Number(item.nQuantity)
                    );

                    priceArray.forEach((item, index) => {
                        const itemValueInWei = ethers.utils.parseUnits(
                            item.toString(),
                            decimals
                        );
                        // Multiply the item value in Wei by the quantity
                        const priceInWei = itemValueInWei.mul(
                            quantityArray[index]
                        );
                        price = price.add(priceInWei);
                    });

                    const quantitySum = quantityArray.reduce(
                        (acc, curr) => Number(acc) + Number(curr),
                        0
                    );

                    setTotalPrice(toEther(price.toString(), decimals));
                    setTotalQuantity(quantitySum);
                    setChainId(res.data.data[0]?.nft.nChainId);
                    setErc20Address(
                        res.data.data[0]?.transactions.sTokenAddress
                    );
                    setTokenSymbol(res.data.data[0]?.transactions.sTokenSymbol);

                    if (res.data.data) {
                        const urls = res.data.data.map(item =>
                            item?.sNftCloudinaryLink || `${item?.nft?.bIsMigrated ? CONFIG.sPinataIpfsUri : CONFIG.sFilebaseIpfsUri}${item?.nft?.sImageHash}`
                        );
                        setImageUrls(urls);
                    }

                    return res.data.data;
                } catch (error) {
                    console.log(error);
                }
            }),
    });

    const { mutate: mutateCart, isPending: deletePending } = useMutation({
        mutationFn: (id) => removeFromCart(id),
        onSuccess: (data) => {
            // notify('success', data.data.message);
            refetch();
        },
        onError: (error) => {
            notify('error', error.data.message || 'Something went wrong!');
        },
    });

    const { mutate: mutateEditCart, isPending: editPending } = useMutation({
        mutationFn: (data) => editCart(data),
        onSuccess: (data) => {
            refetch();
            // notify('success', data.data.message);
        },
        onError: (error) => {
            notify('error', error.data.message || 'Something went wrong!');
        },
    });

    const {
        data,
        write: writeBulkBuy,
        isLoading,
    } = useContractWrite({
        address: _contracts_web3.addresses[chainId]?.media,
        abi: _contracts_web3.abis.media,
        functionName: 'bulkBuy',
        account: address,
        value: CONFIG.token[cartData?.[0]?.ePaymentMode]?.decimals
            ? 0
            : toWei(totalPrice.toString(), decimals),
        onError(error) {
            setIsEnabled(false);
            setIsApproved(false);
            setIsEnoughBalance(false);
            notify('error', parseRevertReason(error.shortMessage));
        },
        onSuccess() { },
    });

    const { isLoading: isLoadingTransaction, isSuccess: isSuccessTransaction } =
        useWaitForTransaction({
            hash: data?.hash,
        });

    useEffect(() => {
        if (isSuccessTransaction) {
            refetch();
            setIsEnabled(false);
            setIsApproved(false);
            setIsEnoughBalance(false);
            notify('success', 'NFT purchased successfully');
        }
    }, [isSuccessTransaction]);

    // Check if ERC20 token is approved for Buy NFT on marketplace
    const {
        isLoading: isGetApproveLoading,
        error,
        isFetching,
    } = useContractRead({
        address: erc20Address,
        abi: _contracts_web3.abis.ERC20,
        functionName: 'allowance',
        enabled: isEnoughBalance,
        chainId: chainId,
        args: [address, _contracts_web3.addresses[chainId]?.market],
        onSuccess(data) {
            if (
                data >= toWei(totalPrice, CONFIG.token[tokenSymbol]?.decimals)
            ) {
                handleBuyNow();
            } else {
                approveWrite();
            }

            setIsEnabled(false);
        },
        onError(error) {
            setIsEnabled(false);
            setIsApproved(false);
            setIsEnoughBalance(false);
        },
    });

    // Check ERC20 Token Balance
    const { isLoading: fetchBalanceLoading, isFetching: balanceFetching } =
        useContractRead({
            address: erc20Address || '',
            abi: _contracts_web3.abis.ERC20,
            functionName: 'balanceOf',
            enabled: isEnabled,
            chainId: chainId,
            args: [address],
            onSuccess(data) {
                if (
                    data >=
                    toWei(totalPrice, CONFIG.token[tokenSymbol]?.decimals)
                ) {
                    setIsEnoughBalance(true);
                } else {
                    setIsEnoughBalance(false);
                    setIsApproved(false);
                    setIsEnabled(false);

                    return notify(
                        'error',
                        `Not enough ${tokenSymbol} token to buy this NFT`
                    );
                }
            },
            onError(error) {
                setIsEnabled(false);
                setIsApproved(false);
                setIsEnoughBalance(false);
            },
        });

    // Approve ERC20 token to Buy NFT on marketplace
    const {
        data: approveData,
        write: approveWrite,
        isLoading: isApproveLoading,
    } = useContractWrite({
        address: erc20Address || '',
        abi: _contracts_web3.abis.ERC20,
        functionName: 'approve',
        account: address,
        chainId: chainId,
        args: [
            _contracts_web3.addresses[chainId]?.market,
            toWei(
                totalPrice,
                CONFIG.token[cartData?.[0]?.transactions.sTokenSymbol]?.decimals
            ),
        ],
        onError(error) {
            setIsEnabled(false);
            setIsApproved(false);
            setIsEnoughBalance(false);
            notify('error', parseRevertReason(error.shortMessage));
        },
    });

    const {
        isLoading: isApproveTransactionLoading,
        isSuccess: isApproveTransactionSuccess,
    } = useWaitForTransaction({
        hash: approveData?.hash,
    });

    useEffect(() => {
        if (isApproveTransactionSuccess) {
            handleBuyNow();
        }
    }, [isApproveTransactionSuccess]);

    const increaseQuantity = (quantity, index) => {
        if (quantity <= cartData[index].transactions.nSaleQuantity) {
            setQuantity((prev) => {
                const newQuantity = [...prev];
                newQuantity[index] = quantity;
                return newQuantity;
            });
            setTotalPrice((prev) => {
                const prevPrice = toWei(prev, decimals);
                const newPrice = toWei(
                    cartData[index].transactions.nBidPrice,
                    decimals
                );
                return toEther(Number(prevPrice) + Number(newPrice), decimals);
            });

            setTotalQuantity((prev) => {
                return prev + 1;
            });

            mutateEditCart({
                sCartId: cartData[index]._id,
                nQuantity: quantity,
            });
        }
    };

    const decreaseQuantity = (quantity, index) => {
        if (quantity >= 1) {
            setQuantity((prev) => {
                const newQuantity = [...prev];
                newQuantity[index] = quantity;
                return newQuantity;
            });
            setTotalPrice((prev) => {
                let prevPrice = toWei(prev, decimals);
                let newPrice = toWei(
                    cartData[index].transactions.nBidPrice,
                    decimals
                );
                return toEther(Number(prevPrice) - Number(newPrice), decimals);
            });
            setTotalQuantity((prev) => {
                return prev - 1;
            });
            mutateEditCart({
                sCartId: cartData[index]._id,
                nQuantity: quantity,
            });
        }
    };

    const handleSubmit = async () => {
        if (chainId !== chain.id) {
            await switchNetwork(chainId);
        } else if (
            cartData[0].transactions?.sTokenAddress ===
            CONFIG.supportedTokens[0].value
        ) {
            handleBuyNow();
        } else {
            setIsEnabled(true);
        }
    };
    function handleBuyNow() {
        const price = toWei(totalPrice, CONFIG.token[tokenSymbol]?.decimals);
        const purchaseData = getCartData();

        writeBulkBuy({
            args: [
                purchaseData.tokenAddresses,
                purchaseData.ids,
                purchaseData.fromAddresses,
                purchaseData.quantities,
                purchaseData.prices,
                purchaseData.commissions,
                price,
            ],
        });
    }

    const getCartData = () => {
        const data = cartData.reduce(
            (acc, item) => {
                acc.tokenAddresses.push(item.nft.sCollectionAddress);
                acc.ids.push(item.nft.nTokenId);
                acc.fromAddresses.push(
                    item.transactions.aSeller[0].sWalletAddress
                );
                acc.quantities.push(item.nQuantity);
                acc.prices.push(toWei(item.transactions.nBidPrice, decimals));
                acc.commissions.push(item.transactions.nCommission || 0);
                return acc;
            },
            {
                tokenAddresses: [],
                ids: [],
                fromAddresses: [],
                quantities: [],
                prices: [],
                commissions: [],
            }
        );
        return data;
    };

    const handleError = (index) => {
        const newImageUrls = [...imageUrls];
        newImageUrls[index] = `${CONFIG.sIpfsUri}${cartData[index]?.nft?.sImageHash}`; // Your fallback image URL
        setImageUrls(newImageUrls);
    };

    return (
        <section>
            <div className='container section-space-small'>
                <div className='text-center'>
                    <h2 className='main-heading-h2'>My Cart</h2>
                </div>
                <div className='flex gap-32 mt-10 xl:gap-16 lg:gap-0 lg:flex-wrap md:mt-5'>
                    {cartData?.length ? (
                        <div className='w-2/3 lg:w-full'>
                            {cartData.map((item, index) => {
                                return (
                                    <div
                                        key={item._id}
                                        className='flex gap-5 border-b-2 border-dark-20 items-center py-10 last:border-0 lg:py-8 md:gap-4 sm:flex-wrap sm:py-5'
                                    >
                                        <div className='flex gap-4 items-center w-2/5 md:gap-3 sm:w-full'>
                                            <div>
                                                <div className='relative w-32 pt-[100%] rounded-2xl overflow-hidden 2xl:w-24 md:w-20'>
                                                    <Link
                                                        to={`/nft/${item.nft._id}`}
                                                        className='absolute w-full h-full top-0 left-0 z-[2]'
                                                    ></Link>
                                                    {item.nft.eType ===
                                                        'image' ? (
                                                        <img
                                                            src={imageUrls[index]}
                                                            alt='NftImg'
                                                            className='NftImg absolute left-0 top-0 object-cover object-center'
                                                            onError={() => handleError(index)}
                                                        />
                                                    ) : item.nft.eType ===
                                                        'video' ? (
                                                        <video
                                                            src={imageUrls[index]}
                                                            alt='NftImg'
                                                            className='rounded-xl absolute top-0 left-0 w-full h-full object-cover object-center scale-[0.99]'
                                                            onError={() => handleError(index)}
                                                        />
                                                    ) : (
                                                        <model-viewer
                                                            alt={item.nft.sName}
                                                            src={imageUrls[index]}
                                                            // ar
                                                            poster={imageUrls[index]}
                                                            shadow-intensity='1'
                                                            camera-controls
                                                            ref={(ref) => {
                                                                modelRef.current =
                                                                    ref;
                                                            }}
                                                            class='NftImg w-full h-full absolute left-0 top-0 object-cover object-center'
                                                            onError={() => handleError(index)}
                                                        ></model-viewer>
                                                    )}
                                                </div>
                                            </div>
                                            <div>
                                                <h6 className='text-2xl text-black font-bold 2xl:text-xl md:text-lg sm:text-md'>
                                                    {item.nft.sName}
                                                </h6>
                                                <p className='text-lg font-bold text-dark-80 2xl:text-md md:text-base'>
                                                    {
                                                        item.nft.collection[0]
                                                            .sName
                                                    }
                                                </p>
                                                <p className='text-base font-bold truncate text-dark-80 2xl:text-sm md:text-xs'>
                                                    {shortenAddress(
                                                        item.nft
                                                            .sCollectionAddress
                                                    )}
                                                </p>
                                            </div>
                                        </div>
                                        <div className='w-1/5 text-center sm:w-auto sm:order-1'>
                                            <div className='inline-block'>
                                                <QuantityControl
                                                    quantity={
                                                        quantity[index] || 0
                                                    }
                                                    index={index}
                                                    increaseQuantity={
                                                        increaseQuantity
                                                    }
                                                    decreaseQuantity={
                                                        decreaseQuantity
                                                    }
                                                    editPending={editPending}
                                                    darkBg={true}
                                                />
                                            </div>
                                        </div>
                                        <div className='w-1/4 text-center sm:w-full sm:text-left sm:order-none'>
                                            <h5 className='text-2xl font-bold text-black 2xl:text-xl md:text-lg'>
                                                {formatNumber(
                                                    item.transactions.nBidPrice
                                                )}{' '}
                                                {item.ePaymentMode}
                                            </h5>
                                        </div>
                                        <div className='w-1/12 text-right sm:w-auto sm:order-1'>
                                            <button
                                                className='border-0 p-1'
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    mutateCart(item._id);
                                                }}
                                                disabled={deletePending}
                                            >
                                                <img
                                                    src={DeleteIcon}
                                                    alt='DeleteIcon'
                                                />
                                            </button>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    ) : null}
                    {!cartData?.length && (
                        <div className='w-full mt-10'>
                            <NoDataFound />
                        </div>
                    )}
                    {cartData?.length ? (
                        <div className='w-1/3 lg:w-full pt-10 sm:pt-5'>
                            <div className='bg-dark-10 rounded-2xl p-6 sm:p-4'>
                                <h6 className='border-b border-dark-40 text-lg font-bold pb-4 sm:text-md'>
                                    Billing Details
                                </h6>
                                <div className='py-3'>
                                    <div className='flex gap-3 justify-between py-2'>
                                        <p className='text-base text-black font-bold sm:text-sm'>
                                            Total Number Of NFTs
                                        </p>
                                        <p className='text-base text-black sm:text-sm'>
                                            {formatNumber(totalQuantity)}
                                        </p>
                                    </div>
                                    <div className='flex gap-3 justify-between py-2'>
                                        <p className='text-base text-black font-bold sm:text-sm'>
                                            Total Payable Amount
                                        </p>
                                        <p className='text-base text-black whitespace-nowrap sm:text-sm'>
                                            {formatNumber(Number(totalPrice))}{' '}
                                            {cartData?.[0]?.ePaymentMode}
                                        </p>
                                    </div>
                                </div>
                                <div className='text-center'>
                                    <button
                                        className='btn-secondary'
                                        onClick={handleSubmit}
                                        disabled={
                                            isLoading ||
                                            isLoadingTransaction ||
                                            isGetApproveLoading ||
                                            fetchBalanceLoading ||
                                            isApproveLoading ||
                                            isApproveTransactionLoading ||
                                            balanceFetching ||
                                            isFetching
                                        }
                                    >
                                        {(isLoading ||
                                            isLoadingTransaction ||
                                            isGetApproveLoading ||
                                            fetchBalanceLoading ||
                                            balanceFetching ||
                                            isApproveLoading ||
                                            isApproveTransactionLoading ||
                                            isFetching) && <ButtonLoader />}
                                        PAY NOW
                                    </button>
                                </div>
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>
        </section>
    );
}

export default MyCart;
