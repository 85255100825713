import { Dialog } from "@headlessui/react";
import React, { useEffect, useState } from "react";

// COMPONENTS
import FileUpload from "../../FormControl/FileUpload";
import Input from "../../FormControl/Input";
import Modal from "../Modal";

// IMAGES
import CloseIcon from "../../../assets/images/close-icon.svg";

import { useMutation } from "@tanstack/react-query";
import { editCollection } from "../../../api/adapters/collection";
import { supportedFileTypes } from "../../../constants/platform-constants";
import { notify } from "../../../utils/common.helper";

function EditCollection(props) {
  const { isPopupOpen, setIsPopupOpen, refetch, editDetail } = props;
  const [inputValue, setInputValue] = useState({ title: "" });
  const [selectedFile, setSelectedFile] = useState();

  function fileOnChange(event) {
    if (event.target.files.length > 0) {
      setSelectedFile(event.target.files[0]);
    }
  }

  const { mutate } = useMutation({
    mutationFn: (data) => editCollection(data),
    onSuccess: (data) => {
      notify("success", data.data.message);
      setInputValue("");
      setSelectedFile();
      setIsPopupOpen(false);
      refetch();
    },
    onError: (error) => {
      notify("error", error.data.message || "Something went wrong");
      setInputValue("");
      setSelectedFile();
      setIsPopupOpen(false);
    },
  });

  useEffect(() => {
    setSelectedFile(editDetail.image);
  }, []);

  const handleSubmit = () => {
    if (inputValue) {
      const data = new FormData();
      // data.append('id', '65004d5754a9aeee6a733dfe');
      data.append("image", selectedFile || undefined);
      data.append("sName", inputValue.title || undefined);
      data.append("sCollectionId", editDetail.collectionId);
      return mutate(data);
    }
  };

  return (
    <Modal isPopupOpen={isPopupOpen} setIsPopupOpen={setIsPopupOpen}>
      <div className="flex justify-between items-start">
        <Dialog.Title
          as="h3"
          className="text-3xl font-black font-monserrat text-black -mt-5 lg:text-2.5xl sm:text-2xl sm:-mt-2"
        >
          Edit Collection
        </Dialog.Title>
        <button
          className="focus-visible:outline-none"
          onClick={() => {
            setIsPopupOpen(false);
            if (selectedFile) {
              setSelectedFile();
            }
          }}
        >
          <img src={CloseIcon} alt="CloseIcon" className="w-7" />
        </button>
      </div>

      <div>
        <div className="mt-7">
          <Input
            placeholder="Enter Collection Title"
            label="Collection Title"
            id="title"
            changeHandler={(e) =>
              setInputValue((prev) => ({
                ...prev,
                title: e.target.value,
              }))
            }
            value={inputValue.title || editDetail.title}
            handleSubmit={handleSubmit}
          />
        </div>
        <div className="mt-7">
          <FileUpload
            label="Collection Image"
            id="collection_image"
            selectedFile={selectedFile}
            onChange={fileOnChange}
            currentFile={editDetail.image}
            supportedFileTypes={supportedFileTypes.collection}
          />
        </div>
        <div className="mt-7">
          <button className="btn-secondary w-full" onClick={handleSubmit}>
            EDIT
          </button>
          <button
            className="btn-border w-full mt-4"
            onClick={() => {
              setIsPopupOpen(false);
              if (selectedFile) {
                setSelectedFile();
              }
            }}
          >
            CANCEL
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default EditCollection;
