import { Dialog } from "@headlessui/react";
import React, { useEffect, useState } from "react";
import {
  useAccount,
  useContractWrite,
  useNetwork,
  useSwitchNetwork,
  useWaitForTransaction,
} from "wagmi";
import _contracts_web3 from "../../configs/contracts.web3";
import { notify } from "../../utils/common.helper";
import { parseRevertReason } from "../../utils/helper";

// COMPONENTS
import Modal from "./Modal";

// IMAGES
import { useNavigate } from "react-router-dom";
import CloseIcon from "../../assets/images/close-icon.svg";
import Input from "../FormControl/Input";
import ButtonLoader from "../Loader/ButtonLoader";

function BurnNftPopup(props) {
  const {
    isPopupOpen,
    setIsPopupOpen,
    nftQuantity,
    nftAddress,
    nftId,
    nftChainId,
    nftType,
  } = props;

  const [inputValue, setInputValue] = useState({
    nftQuantity: "",
  });
  const [error, setError] = useState();

  const navigate = useNavigate();
  const { address } = useAccount();
  const { chain } = useNetwork();
  const { switchNetwork } = useSwitchNetwork({
    onSuccess: () => {
      const args = nftType === "ERC721" ? [nftId] : [nftId, nftQuantity];
      burnNftWrite({ args });
    },
    onError: (error) => {
      notify("error", parseRevertReason(error.shortMessage));
    },
  });

  // Contract call to put NFT on sale
  const {
    data,
    write: burnNftWrite,
    isLoading,
  } = useContractWrite({
    address: nftAddress,
    abi: _contracts_web3.abis[nftType],
    functionName: "burn",
    account: address,
    onError(error) {
      console.log(error);
      notify("error", parseRevertReason(error.shortMessage));
    },
  });

  const { isLoading: isBurnLoading, isSuccess: isBurnSuccess } =
    useWaitForTransaction({
      hash: data?.hash,
    });

  useEffect(() => {
    if (isBurnSuccess) {
      navigate("/my-nft");
      setIsPopupOpen(false);
      // notify('success', 'Sale created successfully');
    }
  }, [isBurnSuccess]);

  const handleBurn = () => {
    if (nftType === "ERC1155" && !inputValue.nftQuantity) {
      return setError("Please enter NFT quantity");
    } else if (nftType === "ERC1155" && inputValue.nftQuantity < 1) {
      return setError("NFT quantity should be greater than 0");
    } else if (nftType === "ERC1155" && inputValue.nftQuantity > nftQuantity) {
      return setError(
        `NFT quantity should be less than or equal to ${nftQuantity} available quantity`
      );
    } else {
      setError("");
    }

    const args =
      nftType === "ERC721" ? [nftId] : [nftId, inputValue.nftQuantity];

    if (nftChainId !== chain.id) {
      switchNetwork(nftChainId);
    } else {
      burnNftWrite({ args });
    }
  };
  return (
    <>
      <Modal isPopupOpen={isPopupOpen} setIsPopupOpen={setIsPopupOpen}>
        <div className="flex justify-between items-start">
          <Dialog.Title
            as="h3"
            className="text-3xl font-black font-monserrat text-black -mt-5 lg:text-2.5xl sm:text-2xl sm:-mt-2"
          >
            Burn NFT
          </Dialog.Title>
          <button
            className="focus-visible:outline-none"
            onClick={() => setIsPopupOpen(false)}
          >
            <img src={CloseIcon} alt="CloseIcon" className="w-7" />
          </button>
        </div>
        <div className="mt-5">
          <p className="text-base text-dark-90">
            Are you sure you want to burn the NFT? Once you will burn it, you
            won’t be able to recover it after burning the NFT.
          </p>
        </div>
        {nftType === "ERC1155" && (
          <div className="mt-7">
            <Input
              placeholder="Enter NFT Quantity"
              label="NFT Quantity"
              id="nftQuantity"
              changeHandler={(e) => {
                setInputValue((prev) => ({
                  ...prev,
                  nftQuantity: e.target.value,
                }));
              }}
              handleSubmit={handleBurn}
              value={inputValue.nftQuantity}
              type="number"
              error={error}
              isRequired={true}
            />
          </div>
        )}
        <div className="mt-10">
          <button
            className="btn-secondary w-full"
            onClick={handleBurn}
            disabled={isLoading || isBurnLoading}
          >
            {(isLoading || isBurnLoading) && <ButtonLoader />} YES
          </button>
          <button
            className="btn-border w-full mt-4"
            onClick={() => {
              setIsPopupOpen(false);
            }}
          >
            CANCEL
          </button>
        </div>
      </Modal>
    </>
  );
}

export default BurnNftPopup;
